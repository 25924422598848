<template>
  <div>
    <div class="input-wrapper mb-2" v-for="(line, i) in lines" :key="i">
      <input type="text" class="form-control" v-model="lines[i]" @input="emitChange">
    </div>
    <div class="mt-1 small">
      <a href="" @click.prevent="addLine">+ Add</a>
    </div>
  </div>
</template>

<script>
import inputMixin from '../../../modules/forms/components/inputs/inputMixin'

export default {
  mixins: [inputMixin],
  data () {
    return {
      lines: ['']
    }
  },
  methods: {
    addLine () {
      this.lines.push('')
    },
    emitChange () {
      this.handleChange(this.lines)
    }
  }
}
</script>

<style scoped>
</style>
